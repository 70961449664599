import { useState, useEffect } from "react";

function useUrl() {
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  return url;
}

export default useUrl;
