import React, { useState, useEffect, useContext } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";

import Button from "@material-ui/core/Button";
import Container from "../components/Container";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { makeStyles } from "@material-ui/core";

import Layout from "../components/layout";
import Banner from "../components/Banner";
import PageDescription from "../components/PageDescription";

import useLanguage from "../hooks/useLanguage";
import useUrl from "../hooks/useUrl";

import i18nContext from "../i18n-context";

const AboutPage = ({ data, pageContext }) => {
  const url = useUrl();
  const lang = useLanguage();
  const muiTheme = useTheme();
  const classes = useStyles();
  const i18n = useContext(i18nContext)[lang];
  const isPhone = useMediaQuery(muiTheme.breakpoints.down("xs"));
  const [showNameInColumn, setNameInColumn] = useState(true);

  const { title, content, excerpt, translated } = pageContext;
  const mediaItemUrl = pageContext.featuredImage?.node?.mediaItemUrl || "";

  const headMembers = data.wp.customTeamMembers.filter(
    (member) =>
      member.language === pageContext.locale && member.is_top_level === 1
  ).sort(compare);

  const members = data.wp.customTeamMembers.filter(
    (member) =>
      member.language === pageContext.locale && member.is_top_level === 0
  ).sort(compare);

  // console.log("members:",members)

  function compare( a, b ) {
    if ( a.tax_position > b.tax_position ){
      return 1;
    }
    if ( a.tax_position < b.tax_position ){
      return -1;
    }
    return 0;
  }

  const useWindowWidth = () => {
    const isBrowser = typeof window !== "undefined";
    const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

    useEffect(() => {
      if (!isBrowser) return false;
      const handleResize = () => setWidth(window.innerWidth);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    return width;
  };
  let windowInnerWidth = useWindowWidth();
  const mobileSizeFirst = windowInnerWidth < muiTheme.breakpoints.values.sm;

  const handleClick = () => {
    setNameInColumn(false);
  };

  return (
    <Layout pageTranslations={translated}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${title} `}</title>

        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={excerpt} />
        <meta property="og:image" content={mediaItemUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta property="og:type" content="article" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={excerpt} />
        <meta name="twitter:image" content={mediaItemUrl} />
      </Helmet>

      <Banner title={title} bannerUrl={mediaItemUrl} />
      <Container className={classes.sansSerif}>
        {excerpt && <PageDescription>{parse(excerpt)}</PageDescription>}

        <div
          className={`${classes.section} ${classes.sansSerif}`}
          component="div"
        >
          {parse(content)}
        </div>

        <div className={classes.teamInfo}>
          <Grid
            container
            justifyContent={isPhone ? "flex-start" : "center"}
            spacing={isPhone ? 2 : 6}
            className={classes.teamInfoColumn}
          >
            {headMembers.map((headMember, index) => (
              <Grid
                key={`team-member-${index}`}
                item
                container
                direction="column"
                alignItems={isPhone ? "flex-start" : "center"}
                xs={12}
                sm={6}
                md={4}
              >
                <Typography
                  variant="h5"
                  align={isPhone ? "left" : "center"}
                  display="inline"
                  className={`${classes.sansSerif} ${classes.fontWeightNormal}`}
                >
                  {headMember.name}
                </Typography>
                <Typography
                  variant="h6"
                  align={isPhone ? "left" : "center"}
                  display="inline"
                  className={`${classes.sansSerif} ${classes.fontWeightNormal}`}
                >
                  {headMember.position}
                </Typography>
              </Grid>
            ))}
          </Grid>
          <Divider className={classes.divider} />
          <Grid
            container
            spacing={isPhone ? 2 : 6}
            className={classes.teamInfoColumn}
          >
            {members.map((member, index) => (
              <Grid
                key={`team-member-${index}`}
                item
                container
                direction="column"
                alignItems={isPhone ? "flex-start" : "center"}
                className={
                  index > 2 && isPhone && mobileSizeFirst && showNameInColumn
                    ? classes.fontHide
                    : ""
                }
                xs={12}
                sm={6}
                md={4}
              >
                <Typography
                  variant="h6"
                  align={isPhone ? "left" : "center"}
                  display="inline"
                  className={`${classes.sansSerif} ${classes.fontWeightNormal}`}
                >
                  {member.name}
                </Typography>
                <Typography
                  variant="body1"
                  align={isPhone ? "left" : "center"}
                  display="inline"
                  className={`${classes.sansSerif} ${classes.fontWeightNormal}`}
                >
                  {member.position}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {isPhone && mobileSizeFirst && showNameInColumn && (
            <Grid container justifyContent="center">
              <Button
                variant="outlined"
                className={classes.showMoreButton}
                onClick={handleClick}
              >
                Show More
              </Button>
            </Grid>
          )}
        </div>
      </Container>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
  section: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(4),
    },
    marginBottom: theme.spacing(8),

    "& h2": {
      fontWeight: 400,
    },
  },
  divider: {
    backgroundColor: "#B3B3B3",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: `-${theme.spacing(1)}px`,
      marginRight: `-${theme.spacing(1)}px`,
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(4)}px`,
    },
  },
  teamInfo: {
    marginBottom: theme.spacing(10),
  },
  teamInfoColumn: {
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(16),
      paddingRight: theme.spacing(16),
    },
  },
  sansSerif: {
    fontFamily: "Open Sans",
  },
  fontWeightNormal: {
    fontWeight: 400,
  },
  showMoreButton: {
    borderRadius: 0,
    borderColor: theme.palette.error.main,
    marginTop: theme.spacing(4),
  },

  fontHide: {
    display: "none",
  },

  // headMemberName: {
  //   fontWeight: 400,
  //   fontSize: "20px",
  //   [theme.breakpoints.up("md")]: {
  //     fontSize: "40px",
  //   },
  // },

  // headMemberTitle: {
  //   fontSize: "15px",
  //   lineHeight: 1,
  //   [theme.breakpoints.up("md")]: {
  //     fontSize: "30px",
  //   },
  // },

  // memberName: {
  //   fontWeight: 400,
  //   fontSize: "15px",
  //   [theme.breakpoints.up("md")]: {
  //     fontSize: "35px",
  //   },
  // },

  // memberTitle: {
  //   fontSize: "12px",
  //   lineHeight: 1,
  //   [theme.breakpoints.up("md")]: {
  //     fontSize: "25px",
  //   },
  // },
}));

//    $locale: String
export const query = graphql`
  query aboutPageQuery {
    wp {
      customTeamMembers {
        id
        is_top_level
        language
        name
        tax_position
        original_id
        position
      }
    }
  }
`;

export default AboutPage;
