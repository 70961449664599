import React from "react";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";

const PageDescription = ({ children }) => {
  return (
    <StyledTypography aria-label="page-description">
      {children}
    </StyledTypography>
  );
};

const StyledTypography = withStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    fontFamily: "Open Sans",
    fontWeight: 400,

    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
    },
  },
}))((classes) => <Typography {...classes} align="justify" component="div" />);

export default PageDescription;
